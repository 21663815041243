@import "/src/_styles/mixin";

.feePage {
  position: relative;
  .slider {
    .slider_bg {
      width: 100%;
      position: relative;
    }
  }

  .content {
    position: relative;
    .content_bg {
      width: 100%;
      .list_packages {
        padding: 2rem 2%;
        padding-top: 0rem;
        align-items: flex-end;
        @include screen_mobile {
          padding-top: 1rem;
        }
        .package_item {
          box-sizing: border-box;
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;
          // padding: 0 1%;
          padding-bottom: 1.5rem;
          margin: 2rem 0%;
          background-color: var(--white-color);
          min-height: 39rem;
          justify-content: flex-start;
          width: 28%;
          min-width: 25.5rem;
          margin: 2rem 0.5rem;

          @include screen_tablet {
            width: 45% !important;
            min-width: 25.5rem;
            min-height: 40rem;
            margin: 1rem 0%;
          }
          @include screen_mobile {
            width: 85% !important;
            min-height: 35rem;
            min-width: 20rem;
            padding-bottom: 1rem;
          }

          .package_item_header {
            background-color: #77badc;
            width: 100%;
            border-top-left-radius: 0.8rem;
            border-top-right-radius: 0.8rem;
            padding: 1rem 0;

            @include screen_mobile {
              padding: 0.75rem 0;
            }

            .ico_white_star {
              width: 1.1rem;
            }
          }
          .original_price_item_unit {
            position: absolute;
            bottom: 0.22rem;
            margin-left: 0.15rem;
            font-size: 0.9375rem;
            @include screen_mobile {
              font-size: 0.75rem;
              bottom: 0.17rem;
            }
          }
          .current_price_item {
            line-height: 49px;
          }
          .current_price_item_unit {
            margin-left: 0.3rem;
            position: relative;
            bottom: 0.2rem;
          }

          .item_detail_fee {
            line-height: 19.78px;
          }

          .ico_tick_detail_1 {
            width: 1.15rem;
            position: absolute;
            right: -0.25rem;
            top: 1.25rem;

            @include screen_mobile {
              top: 0.5rem;
            }
          }

          .ico_tick_detail {
            width: 1.15rem;
            position: absolute;
            right: -0.25rem;
            top: 0.5rem;
          }

          .package_item_content {
            align-items: flex-start;
            padding: 0rem 5%;
            padding-top: 0.6rem;
          }
          .btn_custom {
            max-width: 100% !important;
            width: 10.8rem !important;
            max-height: 100% !important;
            height: 3rem !important;
          }
        }
      }
    }
    .text_footer_content {
      padding: 2rem 0;
      width: 100%;

      @include screen_mobile {
        background-color: var(--white-color);
        padding: 1.5rem 0;
      }
    }

    .icon_payment {
      width: 3rem;
      height: auto;
    }
  }
}
