@import "/src/_styles/mixin";

.faqsPage {
  .content {
    width: 100%;
    padding: 2.25rem 0 3rem;
    @include screen_mobile {
      padding: 1.25rem 0 2.5rem;
    }
    .container {
      width: 88%;
      min-height: 40rem;

      @include screen_mobile {
        width: 92%;
      }

      .content_categories {
        width: 30%;
        border-radius: 1rem;
        padding: 0.5rem 0 0.75rem;
        margin-right: clamp(1rem, 1.65vw, 1.45rem);

        @include screen_mobile {
          width: 100%;
          border-radius: 0;
          padding: 0;
          margin-right: 0;
          box-shadow: none;
          border: none;
        }

        .content_categories-header {
          padding: 0.25rem clamp(0.75rem, 1.75vw, 1.75rem) 0.75rem;
          font-size: clamp(1.25rem, 1.8vw, 1.625rem);
          @include screen_mobile {
            font-size: clamp(0.875rem, 5vw, 1.125rem);
            padding: 0.25rem 0 0.75rem;
          }
        }

        .categories_list {
          .category_item {
            padding: 0.5rem clamp(0.75rem, 1.75vw, 1.75rem);

            @include screen_mobile {
              background-color: var(--white-color) !important;
              position: relative;
              z-index: 2;
              padding: 0.65rem 1rem 0.65rem 2.2rem;
              &:not(&:last-child) {
                border-bottom: 1px solid var(--border-color);
              }
            }

            &:hover div {
              color: var(--primary-green);
            }

            .category_item-title {
              font-size: clamp(1.125rem, 1.8vw, 1.375rem);

              @include screen_mobile {
                font-size: clamp(0.875rem, 5vw, 1rem);
              }
            }
          }

          .category_item--selected {
            background-color: #c6fbdd;
            &:hover div {
              color: var(--text-color);
            }
          }

          .border--selected {
            position: absolute;
            left: 0;
            top: 0;
            width: clamp(0.35rem, 0.6vw, 0.45rem);
            height: 100%;
            background-color: var(--primary-green);
          }
        }

        // Mobile
        .category_header {
          border: 1px solid var(--primary-green);
          padding: 0.55rem 1rem 0.55rem 2.2rem;
          border-radius: 0.4375rem;
          cursor: pointer;
          .category_title {
            @include screen_mobile {
              font-size: clamp(0.875rem, 5vw, 1rem);
            }
          }
          .img_dropdown {
            @include screen_mobile {
              min-width: clamp(2rem, 2.75vw, 2.4rem);
              min-height: clamp(2rem, 2.75vw, 2.4rem);
              width: clamp(2rem, 2.75vw, 2.4rem);
              height: clamp(2rem, 2.75vw, 2.4rem);
            }
          }
        }

        .modal_category_mobile {
          @include screen_mobile {
            position: absolute;
            top: calc(100% + 0.45rem);
            left: -0.25rem;
            right: 0;
            z-index: 2;
            border-radius: 0.75rem;
            background-color: var(--white-color) !important;
            overflow: hidden;
            padding: 0.5rem 0;
            width: calc(100% + 0.5rem);
          }
        }
      }

      .content_questions {
        width: 70%;
        flex: 1;
        @include screen_mobile {
          width: 100%;
          padding-top: 2rem;
        }

        .content_questions-title {
          font-size: clamp(1.35rem, 2.25vw, 1.875rem);
        }
      }

      .title_mobile {
        @include screen_mobile {
          font-size: clamp(0.875rem, 5vw, 1.125rem);
          padding: 0.25rem 0 1rem;
        }
      }
    }
  }
}
