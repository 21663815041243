.modal_search_container {
  width: 40%;
  height: 70vh;
  margin-top: auto;
  margin-bottom: auto;
  background-color: var(--white-color);
  min-height: 30rem;
  position: relative;
  min-width: 26rem;
  max-width: 30rem;
  border-radius: 1rem;
  padding: 1rem 1rem 1.25rem;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    min-width: 22rem;
  }

  .modal_search_header {
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    background-color: var(--white-color);
    padding: 0.25rem 0 1rem;

    .modal_search_close {
      img {
        width: 1.5rem;
        height: auto;
      }
    }
  }

  .search_input {
    .input_text_base {
      height: 3rem !important;
      padding: 0 17px;
      border-radius: 1.3rem;
      margin-bottom: 1rem;
    }
  }

  .list_option {
    flex: 1;
    overflow-y: scroll;

    .search_option_item {
      padding: 0.3rem 0.5rem;
      user-select: none;
    }
  }
}