@import "/src/_styles/mixin";

.modal_confirm_otp {
  //   padding-top: 2rem;
  margin-top: 3rem;
  border-top: 2rem solid var(--primary-green);
  margin-bottom: 10vh;

  .btn_auth_container {
    width: 100%;
    padding-top: 3rem;

    @media screen and (max-width: 768px) {
      flex-direction: column;

      .otp-btn-responsive {
        width: 100% !important;
        max-width: 80% !important;
        max-height: 10vw !important;
      }
    }
  }

  .img_tick_success {
    width: 1.7rem;
    min-width: 1.3rem;
  }

  .input_text_base {
    @include screen_mobile {
      height: 3.3rem !important;
    }

    .input_text_base_content {
      .icon_label {
        display: none;
      }

      .input_text {
        text-align: center;
      }
    }
  }
}

.style_btn_agree_authentication_teacher {
  gap: 20px;
}