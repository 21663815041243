@import "/src/_styles/mixin";

.profilePage {
  .profile_title_container {
    width: 100%;
    background-color: var(--primary-green);
    .profile_title {
      margin: 0 auto;
      max-width: 85vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 0;
    }

    .icon_profile {
      width: 2.8rem;
    }
  }

  .content_profile {
    padding: 5rem 0;
    margin: 0 auto;
    max-width: 85vw;
    display: flex;
    flex-direction: column;

    @include screen_mobile {
      max-width: 94% !important;
      width: 94%;
      padding: 3rem 0;
    }

    .profile_edit_container {
      justify-content: space-between;
      align-items: flex-start;
      padding-bottom: 3rem;

      @include screen_mobile {
        flex-direction: column;
      }

      .avt_container {
        margin: 0 auto;
        @include screen_mobile {
          width: 100%;
        }
        .img_avt_profile {
          width: 14rem;
          height: 14rem;
          min-width: 14rem;
          min-height: 14rem;
          border-radius: 50%;

          @include screen_mobile {
            width: 10rem;
            height: 10rem;
            min-width: 10rem;
            min-height: 10rem;
          }
        }
        .icon_edit_avt {
          width: 1.8rem;
          height: auto;

          @include screen_mobile {
            width: 1.5rem;
          }
        }
      }

      .form_input {
        max-width: 100%;
        .input_text_base,
        .input_date_base,
        .input_select_main_wrapper {
          @include screen_mobile {
            height: 3.3rem !important;
          }
        }

        @include screen_mobile {
          width: 100%;
          padding-top: 2.5rem;
        }

        .inputs_address_mobile {
          @include screen_mobile {
            flex-direction: column;
          }
        }

        .input_radio_base .drop_down .option_item span {
          font-family: "AvertaStdCY" !important;
        }
        .field_province {
          @include screen_mobile {
            width: 100%;
          }
          .input_select_main {
            .input_text {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
        .field_district {
          @include screen_mobile {
            width: 100%;
          }
          .input_select_main_wrapper {
            padding-left: 3% !important;
            padding-right: 0 !important;

            @include screen_mobile {
              padding: 0 2vw !important;
            }

            .input_select_main {
              width: 100% !important;
            }
            .input_text {
              max-width: 80% !important;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              @include screen_mobile {
                max-width: 100% !important;
              }
            }

            .icon_label {
              display: none !important;
              @include screen_mobile {
                display: flex !important;
                justify-content: center;
              }
            }

            .cursor_pointer {
              display: flex !important;
            }
          }
        }

        .field_gender {
          position: relative;
          @include screen_mobile {
            width: 48% !important;
          }
          .input_select_main_wrapper {
            overflow: hidden;
            position: relative;
            .input_select_main {
              width: 100% !important;
            }
            .input_text {
              position: relative;
              max-width: 65% !important;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        .birthday_gender_container {
          // margin-bottom: 0.8rem;
          .input_date_base_container {
            width: 60% !important;

            @include screen_mobile {
              width: 48% !important;
            }
          }
        }

        .grade_container {
          margin-left: 3%;
          margin-bottom: 1.5rem;
          .title_grade {
            // margin-right: 5%;
            width: 45%;

            .name_input_grade {
              margin-left: 5%;
            }
          }
        }
      }

      .btn_save_container {
        @include screen_mobile {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding-top: 2rem;
          .btn_custom {
            height: 2.6rem !important;
          }
        }
        .btn_custom {
          height: 3rem !important;
          min-width: 8rem;
        }
      }
    }

    .payment_history {
      .table_payment_history {
        margin: 4rem 0;
        display: flex;
        flex-direction: column;
        // max-height: 25rem;
        // overflow-y: scroll;
        scroll-behavior: smooth;
        position: relative;
        @include screen_mobile {
          max-height: 40rem;
          overflow-y: initial;
          max-height: 100%;
          margin: 2rem 0 2rem;
          width: 100%;
        }
        table {
          border-collapse: collapse;
        }

        th {
          background-color: #f5f5f5;
          border: 1px solid #f5f5f5;
          padding: 1rem 0;
          position: sticky;
          top: 0;
        }

        td {
          background-color: #fff;
          border: 2px solid #f5f5f5;
          padding: 1.5rem 0;
        }
      }
    }

    .box_payment_container {
      width: 100%;
      .box_payment_item {
        @include screen_mobile {
          width: 100%;
          border-radius: 0.8rem;
          padding: 1.5rem 5%;
          margin-bottom: 1.5rem;
          background-color: var(--white-color);
          border: 1px solid var(--border-color);
        }

        .item_left_divider {
          justify-content: flex-start !important;
          width: 50%;
          border-left: 2px solid var(--text-color);
        }

        .icon_box_payment {
          width: 1.7rem;
          height: auto;
          margin-left: 25%;

          @include screen_mobile {
            width: 1.5rem;
            height: auto;
            margin-left: 15%;
          }
        }

        .icon_box_tick {
          width: 1.2rem;
          height: auto;
        }
      }
    }
  }

  .icon_save_profile {
    width: 15%;

    @include screen_mobile {
      width: 16%;
    }
  }

  .footer_profile {
    padding: 0 7% 1rem;

    @include screen_mobile {
      padding: 0 2% 1rem;
    }
  }
}
