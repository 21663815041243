.modal_filter_container {
  width: 96%;
  height: auto;
  margin-top: auto;
  margin-bottom: auto;
  background-color: var(--white-color);
  border: 1px solid var(--white-color);
  min-height: 70vh;
  position: relative;
  .modal_filter_header {
    background-color: var(--primary-green);
    padding: 0.75rem 0;
    .icon_close_modal {
      position: absolute;
      float: right;
      right: 0.5rem;

      img {
        width: 1.9rem;
        height: auto;
      }
    }
  }

  //   .modal_filter_content {
  //   }
  .btn_filter_container {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 1.5rem;
  }
}
