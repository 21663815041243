$student-color: #c2e68b;
$teacher-color: #8acec6;
$parent-color: #9cc4e6;

$student-text-color: #95bc59;
$teacher-text-color: #00a99d;
$parent-text-color: #6593bb;

$active-class: #00b9b7;
$normal-class: #979797;

.register_account_type {
    .login-form-container_content {
        @media screen and (max-height: 800px) {
            margin-top: -140px;
        }
    }

    span,
    p {
        font-size: 18px;
    }

    .step_register_name {
        margin-bottom: 20px;
    }

    .account_content_title {
        margin-bottom: 14px;

        span {
            font-size: 1.25rem;
        }
    }

    .account_content {
        margin-bottom: 24px;

        &_list {
            display: flex;

            &_item {
                overflow: hidden;
                flex: 1;
                margin-right: 34px;
                position: relative;
                height: 127px;
                width: 127px;
                border-radius: 25px;
                cursor: pointer;

                &:last-child {
                    margin-right: 0px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }

    .account_detail_hidden {
        display: none;
    }

    .account_detail_class {
        position: relative;
        z-index: 1;
        margin-bottom: 60px;

        @media screen and (max-height: 800px) {
            margin-bottom: 30px;
        }

        &_title {
            margin-bottom: 14px;

            span {
                font-size: 1.25rem;
            }
        }

        &_list {
            display: flex;
            gap: 24px;

            &_item {
                flex: 1;
                position: relative;
                height: 93px;
                border-radius: 25px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0px 1px 3px #cccccc;
                background-color: white;

                svg {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate3d(-50%, -50%, 0);
                }

                .class_number {
                    font-size: 25px;
                    color: $normal-class;
                    font-weight: 600;
                    display: flex;
                    margin-top: 4px;
                }

                &.active {

                    svg,
                    path {
                        stroke: $active-class;
                    }

                    .class_number {
                        color: $active-class;
                    }
                }
            }
        }
    }

    .account_detail_school {
        position: relative;
        z-index: 1;
        margin-bottom: 60px;

        @media screen and (max-height: 800px) {
            margin-bottom: 30px;
        }

        &_title {
            margin-bottom: 14px;

            span {
                font-size: 1.25rem;
            }
        }

        &_list {
            display: flex;
            justify-content: center;

            &_item {
                margin: 0px 12px;
                position: relative;
                height: 93px;
                width: 103px;
                border-radius: 13px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0px 1px 3px #cccccc;
                background-color: white;

                svg {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate3d(-50%, -50%, 0);
                }

                .school_type {
                    font-size: 18px;
                    color: $normal-class;
                    font-weight: 600;
                    text-align: center;
                    text-transform: uppercase;
                }

                &.active {
                    .school_type {
                        color: $active-class;
                    }
                }
            }
        }
    }

    .account_detail_placeholder {
        height: 134px;
        visibility: hidden;
        opacity: 0;
        margin-bottom: 60px;

        @media screen and (max-height: 800px) {
            margin-bottom: 30px;
        }

        @media screen and (max-width: 768px) {
            height: 90px;
        }
    }

    .button_submit {
        margin-bottom: 22px;
    }

    .login_text {
        cursor: pointer;

        &:hover {

            p,
            a {
                color: #35b5c3;
            }
        }
    }

    .blur_role {
        opacity: 0.5;
    }
}