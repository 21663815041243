@import "/src/_styles/mixin";

.box_title {
  padding: 0.35rem 0.9vw;
  border-radius: 3rem;

  @include screen_mobile {
    padding: 0.2rem 5vw;
  }
}
