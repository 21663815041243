@import "/src/_styles/mixin";

// .bg-register {
//   max-height: 100vh;
//   overflow-y: hidden;

//   @include screen_mobile {
//     overflow: unset;
//     max-height: unset;
//   }
// }

.choose-org-container {
  width: 100%;
  display: block;
  padding: 5% 15% 0;

  @include screen_mobile {
    padding: 10% 0;
  }
}

.choose-org-heading {
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;

  @include screen_mobile {
    font-size: 1.6rem;
  }
}

.org-list-container {
  margin-top: 94px;
  display: flex;
  justify-content: center;
  overflow-y: auto;

  @include screen_mobile {
    padding: 0 16px;
    margin-top: 26px;
    overflow-y: unset;
  }

  @media screen and (max-height: 800px) {
    margin-top: 40px;
  }
}

.org-list {
  max-height: 64vh;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;

  @include screen_mobile {
    max-height: unset;
    gap: 32px;
  }
}

.org-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;
}

.org-item-img {
  display: flex;
  justify-content: center;

  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: contain;

    @include screen_mobile {
      width: 80px;
      height: 80px;
    }
  }
}

.org-item-name {
  p {
    font-size: 18px;
    font-weight: 600;
    text-align: center;

    @include screen_mobile {
      font-size: 14px;
    }
  }
}