@import "/src/_styles/mixin";

.advisementNews {
  background-color: var(--white-color);
  .content {
    position: relative;
    padding: 2rem 3%;

    @include screen_mobile {
      padding-bottom: 6rem;
      padding-top: 1.5rem;
    }

    .img_banner_container {
      width: 40%;
      .img_banner_advisement {
        width: 85%;
      }
    }

    .advisement_register_container {
      width: 50%;

      @include screen_mobile {
        width: 100%;
      }
      //   align-items: flex-start;
      justify-content: flex-start;

      .title_register_advisement {
        @include screen_mobile {
          margin-bottom: 1.5rem !important;
        }
      }
      .modal_container {
        padding: 1rem 4%;
      }
      .modal_msg {
        // color: var(--primary-green) !important;
      }

      .icon_sucess_msg {
        width: 15% !important;
      }

      .modal_input_advisement {
        border: 1px solid var(--border-color);
        padding: 2.5rem 0;
        border-radius: 0.8rem;

        @include screen_mobile {
          background-color: var(--white-color);
          box-shadow: none;
          border: none;
          padding: 2rem 0 2rem;
        }
      }

      .form_input_advisement {
        width: 90%;

        .input_text_base {
          @include screen_mobile {
            height: 3.4rem !important;
          }
        }

        @include screen_mobile {
          width: 96% !important;
        }

        .input_text_base_container {
          width: 100% !important;
        }
      }

      .modal_container {
        @include screen_mobile {
          width: 88%;
        }
      }
      .btn_custom {
        @include screen_mobile {
          height: 3rem !important;
        }
      }
    }
  }
}
