.select_double_container {
  position: relative;
  //   min-width: 15%;
  //   max-width: 18%;
  //   width: 18%;
  //   margin-right: 2%;
  width: 100%;
  cursor: pointer;
  position: relative;
  padding: 0 3.5%;
  .select_title {
    margin: 0.5rem 0;
    padding: 1.2rem 0;
    border-bottom: 1px solid var(--border-color);
    .select_title_main {
      margin-bottom: 0.2rem;
    }
    .icon_drop_custom {
      margin-left: 0.5rem;
    }
  }

  .list_option_custom {
    width: 70%;
    position: absolute;
    top: 80%;
    right: 4%;
    max-height: 20rem;
    min-height: 10rem;
    overflow: auto;
    background-color: var(--white-color);
    border-radius: 0.6rem;
    border-radius: 0.6rem;
    z-index: 99;
    border: 1px solid var(--border-color);
    border-top: 0;

    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #bbbbbb;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #8b8b8b;
    }

    .option_item_custom {
      color: var(--text-color);
      padding: 1rem 0;
    }
  }
}
