@import "/src/_styles/mixin";

.contactPage {
  position: relative;
  width: 100%;
  background-color: var(--white-color);
  .content {
    // z-index: 0;
    .content_1 {
      padding-left: 9%;
      padding-top: 5vh;
      // z-index: 99;

      .btn_text {
        @include screen_tablet {
          font-size: 1.1rem !important;
        }
      }

      @include screen_mobile {
        flex-direction: column-reverse;
        padding-left: 0;
        padding-top: 1.8rem;
        padding-bottom: 6rem;
      }

      .content_left {
        margin-right: 10%;

        @include screen_mobile {
          width: 100%;
          padding-left: 4%;
          padding-right: 4%;
          padding-bottom: 3rem;
          margin-right: 0;
        }

        .form_input_contact {
          .input_text_base_container {
            .icon_label {
              display: none !important;
            }
          }
          .input_text_base {
            height: 3.3rem !important;
          }
        }

        .title_send_msg {
          @include screen_pc {
            text-transform: uppercase;
          }
        }

        .content_left_ratio {
          padding: 0 5%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;

          .title_ratio {
            padding-right: 10%;
          }
          .icon_ratio_contact {
            width: 1.25rem;
            height: auto;
          }

          .text_ratio {
            font-size: 1.5rem;

            @include screen_mobile {
              font-size: 0.875rem;
            }
          }
        }

        .input_news {
          width: 96% !important;
        }

        .text_area_news {
          max-width: 96% !important;
          width: 96% !important;
        }

        .btn_custom {
          border: 1px solid var(--text-color) !important;
        }
      }

      .content_right {
        padding-top: 6rem;
        // z-index: 99;
        @include screen_mobile {
          width: 100%;
          padding-top: 0;
          padding-bottom: 3rem;
        }

        .content_right_title {
          padding-left: 4%;
          padding-bottom: 1.5rem;
        }
      }
    }

    .content_banner {
      margin-top: -10vw;
      // z-index: -999;

      // .content_banner_img {
      //   // z-index: -999;
      // }

      // @include screen_mobile {
      //   margin-top: -60%;
      // }
    }
  }
}
