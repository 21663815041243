@import "/src/_styles/mixin";

.select-question-container {
  border-radius: 0.9rem;
  padding: 0.35rem 1.25rem 0.35rem;
  margin-top: 1rem;

  @include screen_mobile {
    padding: 0 1rem 0.35rem;
  }

  .question_header {
    padding: 1rem 0;

    @include screen_mobile {
      padding: 0.85rem 0 0.65rem;
    }
    .question_name {
      font-size: clamp(1.125rem, 1.8vw, 1.375rem);
      margin-right: 0.5rem;

      @include screen_mobile {
        font-size: clamp(0.875rem, 5vw, 1.125rem);
      }
    }

    .img_dropdown {
      min-width: clamp(2rem, 2.75vw, 2.4rem);
      min-height: clamp(2rem, 2.75vw, 2.4rem);
      width: clamp(2rem, 2.75vw, 2.4rem);
      height: clamp(2rem, 2.75vw, 2.4rem);
    }

    .question_divider_header {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 40%;
      height: 2px;
      background-color: var(--primary-green);
    }
  }

  .question_data {
    padding: 1rem 0rem;
    .question_content_left {
      margin-right: 6%;

      @include screen_mobile {
        margin-right: 0;
      }
    }
    .question_content_text {
      font-size: clamp(1rem, 1.6vw, 1.25rem);

      @include screen_mobile {
        font-size: clamp(0.875rem, 4.5vw, 1rem);
      }
    }

    .question_content_list {
      .img_app_download {
        width: 8rem;
        min-width: 4rem;
        @include screen_mobile {
          width: 7rem;
          min-width: 4rem;
        }
      }
    }

    .question_content_image {
      width: 25%;
      max-width: 25%;
      min-width: 25%;

      @include screen_mobile {
        width: 62%;
        max-width: 62%;
        max-width: 28rem;
        min-width: 7.5rem;
        padding-top: 1.25rem;
      }

      img {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
      }
    }

    .question_content_list_images {
      .question_content_image_smaller {
        width: auto;
        max-width: 25%;

        &:not(:last-child) {
          margin-right: 2.5%;
        }

        @include screen_mobile {
          margin-right: 0;
          width: 62%;
          max-width: 28rem;
          min-width: 7.5rem;
          padding-top: 1rem;
          &:first-child {
            padding-top: 1.25rem;
          }

          img {
            width: 100%;
            min-width: 100%;
          }
        }
      }
    }
  }
}
