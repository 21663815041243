@import "/src/_styles/mixin";
.introPage {
  .content_intro {
    padding-bottom: 2.2rem;
    .content_1 {
      min-height: 55rem;
      z-index: 0;

      .img_intro_tree_container {
        width: 100%;
        height: 100%;
        z-index: -1;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        overflow: hidden;
        img {
          position: relative;
          height: 93%;
          width: auto;
          overflow: hidden;
        }
      }

      .img_title_intro {
        width: 20.8rem;
        top: 2rem;
        left: 6vw;
      }

      .img_top_title {
        left: 35%;
        top: 2.2rem;
        img {
          width: 6.625rem;
        }
      }

      .list_intro {
        padding-top: 2rem;
        .item_intro {
          background-color: var(--white-color);
          border: 1px solid var(--black-color);
          padding: 0.8rem 1.8rem;
          border-radius: 1.2rem;
          margin-bottom: 5%;
          img {
            width: 7.6rem;
          }
        }
        .list_intro_left {
          width: 48%;
          max-width: 48%;
          padding-top: 4%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          .item_intro_1_left {
            width: 72%;
            position: relative;
            margin-right: -2vw;
            // margin-top: 3%;

            img {
              position: absolute;
              left: -9rem;
              width: 10rem;
            }
          }
          .item_intro_2_left {
            width: 82%;
            position: relative;
            margin-right: 12%;
            margin-top: 4vw;
            img {
              position: relative;
              left: -3%;
              padding-right: 0.2rem;
            }
          }
          .item_intro_3_left {
            width: 95%;
            position: relative;
            margin-right: -2vw;
            margin-top: 4vw;
            flex-direction: row-reverse;
            img {
              position: relative;
              right: -3%;
              padding-right: 0.2rem;
            }
          }
        }

        .list_intro_right {
          width: 48%;
          max-width: 48%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-top: -2rem;
          .item_intro_1_right {
            width: 68%;
            position: relative;
            margin-left: 4vw;
          }
          .item_intro_2_right {
            width: 68%;
            position: relative;
            margin-left: 10vw;
            // margin-top: 1vw;
            img {
              position: relative;
              left: -3%;
              padding-right: 0.2rem;
              width: 5rem;
            }
          }
          .item_intro_3_right {
            width: 80%;
            position: relative;
            margin-left: 6.5vw;
            // margin-top: -1vw;
            flex-direction: row-reverse;
            img {
              position: relative;
              right: -3%;
              padding-right: 0.2rem;
            }
          }
          .item_intro_4_right {
            width: 76%;
            position: relative;
            margin-left: 6vw;
            margin-top: 1vw;
            img {
              position: relative;
              left: -3%;
              padding-right: 0.2rem;
            }
          }
        }
      }
    }

    .content_1_mobile {
      min-height: 60rem;

      .img_title_intro {
        width: 14rem;
        top: 1.5rem;
        left: calc(90% - 14rem);
        float: right;
      }
      .list_intro_mobile {
        padding-top: 2.2rem;

        .img_item_intro {
          top: -2.7rem;
          width: 4.2rem;
        }

        .img_item_intro_1 {
          width: 5.125rem;
          left: -1rem !important;
          top: -2.3rem;
        }
        .img_item_intro_2 {
          top: -3.2rem;
          right: -0.7rem !important;
        }

        .img_item_intro_2,
        .img_item_intro_5,
        .img_item_intro_7 {
          width: 3.75rem;
        }
        .img_item_intro_3 {
          width: 5.5rem;
          left: -2.5rem !important;
        }

        .item_intro_mobile {
          background-color: var(--white-color);
          border: 1px solid var(--black-color);
          border-radius: 1.1875rem;
          padding: 1.2rem calc(0.8rem + 1.5vw);
          width: 80%;
          margin-bottom: 2.3rem;
        }
      }
    }

    .content_2 {
      .content_missions {
        width: 100%;
        padding: 2.5rem 0 1.5rem;

        @include screen_mobile {
          padding-top: 1.5rem;
        }
        .title_mission {
          padding-bottom: 2rem;
          @include screen_mobile {
            font-size: clamp(1.2rem, 6vw, 1.5rem);
            padding-bottom: 1rem;
          }
        }
        .list_missions {
          width: 60%;
          min-width: 35rem;
          background-color: var(--white-color);
          @include screen_mobile {
            width: 90%;
            min-width: auto;
          }
          .mission_item {
            padding-bottom: 1rem;
            z-index: 2;

            .text_item_mission {
              padding-left: 2rem;
              line-height: 1.5rem;

              @include screen_mobile {
                font-size: clamp(0.9rem, 6vw, 1rem);

                padding-left: clamp(0.8rem, 4vw, 1.1rem);
              }
            }
            .img_item_mission {
              width: 3.5rem;
              background-color: var(--white-color);

              @include screen_mobile {
                width: clamp(2rem, 10vw, 2.2rem);
              }
            }
          }

          .border_abs_side {
            top: 5%;
            left: 1.1rem;
            height: 85%;
            width: 0.8px;
            background-color: var(--black-color);
          }
        }

        .icon_container {
          .icon_left_container {
            position: absolute;
            left: 0;
            top: 33%;
            img {
              width: 10vw;
              min-width: 5rem;
            }
          }

          .icon_right_container {
            position: absolute;
            right: 0;
            top: 33%;
            img {
              width: 10vw;
              min-width: 5rem;
            }
          }
        }
      }

      .scholary_container {
        width: 100%;
        margin-bottom: 1rem;
        .title_schoolary {
          width: 100%;
          @include screen_mobile {
            font-size: clamp(1rem, 5.5vw, 1.375rem);
            background-color: #e8f8fc;
            vertical-align: middle;
            padding: 2.2rem 0;
          }
          span {
            @include screen_mobile {
              font-size: clamp(1.5rem, 8vw, 1.875rem) !important;
            }
          }

          .icon_schoolary_title {
            z-index: 0;
            .icon_left_schoolary {
              left: 0;
              top: 10%;
              img {
                width: 6vw;
                min-width: 5rem;
              }
            }
            .icon_right_schoolary {
              right: 0;
              top: 12%;
              img {
                width: 6vw;
                min-width: 2.2rem;
              }
            }
          }
        }
        .list_schoolarly {
          position: relative;
          width: 100%;
          height: 100%;
          display: table;
          table-layout: fixed;
          padding-bottom: 2rem;
          .item_scholarly {
            border-top: 1px solid #000;
            height: 100%;
            display: table-cell;
            text-align: center;
            vertical-align: text-top;
            padding: 2rem 0;

            .title_eng_scholarly {
              padding-left: 0.25rem;
            }
            .item_scholarly_img {
              width: 75%;
            }

            .img_scholary_ico_container {
              height: 6rem;
              .item_scholary_ico {
                width: 4rem;
              }
            }

            .list_detail_content {
              text-align: start;
              padding: 0 1.8rem;
            }
          }
          .item_dot_1,
          .item_dot_2,
          .item_dot_3,
          .item_dot_4,
          .item_dot_5 {
            &::before {
              content: "\2022";
              position: absolute;
              top: 0;
              left: -1rem;
              font-size: 1.5rem;
            }
          }

          .item_dot_1 {
            &::before {
              color: #c84242;
            }
          }
          .item_dot_2 {
            &::before {
              color: #f29500;
            }
          }
          .item_dot_3 {
            &::before {
              color: #fdcd04;
            }
          }
          .item_dot_4 {
            &::before {
              color: #00e2a0;
            }
          }
          .item_dot_5 {
            &::before {
              color: #24bbe0;
            }
          }
        }

        .item_scholary_mobile {
          padding: 1.5rem 1rem 1.5rem 0.8rem;
          @include screen_mobile {
            justify-content: center;
          }
          .item_scholary_img_title {
            width: 48%;
            max-width: 12rem;
            padding-right: 0.5rem;
          }

          .list_detail_content_mobile {
            @include screen_mobile {
              width: 50%;
            }

            .detail_item_content {
              font-size: 0.875rem;
              div {
                font-size: 0.875rem;
              }
              @include screen_mobile {
                line-height: 1.12rem;
              }
            }
          }
        }
      }

      .banner_side_container {
        padding: 0.3rem 0 1.3rem;
        width: 100%;
        img {
          width: 100%;
        }
      }

      .scholary_detail_container {
        background-color: #e8f8fc;
        padding: 2.5rem 0 0 3vw;
        display: flex;
        width: 94%;

        @include screen_mobile {
          width: 100%;
          flex-direction: column;
          align-items: center;
          padding-top: 1.5rem;
          padding-left: 0;
        }

        .scholary_detail_content {
          width: 52%;
          max-width: 52%;
          padding-bottom: 2rem;

          @include screen_mobile {
            width: 88%;
            max-width: 88%;
          }

          .title_schoolary_detail {
            @include screen_mobile {
              font-size: clamp(0.9rem, 5.5vw, 1rem);
            }
            span {
              @include screen_mobile {
                font-size: clamp(0.9rem, 5.5vw, 1rem) !important;
              }
            }
          }

          .title_schoolary_result {
            @include screen_mobile {
              font-size: clamp(1rem, 5.5vw, 1.125rem);
            }
            span {
              @include screen_mobile {
                font-size: clamp(1rem, 5.5vw, 1.125rem) !important;
              }
            }
          }

          .schoolary_detail_result {
            padding-top: 1.5rem;

            .item_result_schoolary {
              margin-left: 1.2rem;
              padding-bottom: 1rem;
            }

            .icon_center_container {
              right: 33%;
              top: 2px;

              img {
                width: 5.5vw;
                min-width: 5rem;
              }
            }
          }
        }

        .img_banner_schoolary {
          width: 48%;
          max-width: 48%;
          justify-content: flex-end;
          @include screen_mobile {
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
}
