@import "/src/_styles/mixin";

.footer_auth_container {
  padding-bottom: 2rem;
  @include screen_mobile {
    flex-direction: column;
    // padding-bottom: 1rem;
    padding-top: 7vh;
    padding-bottom: 0;
  }

  .footer_auth_first {
    @include screen_mobile {
      width: 100% !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 0.5rem;
    }
  }
}
