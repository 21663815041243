@import "/src/_styles/mixin";

.homepage {
  position: relative;
  .slider {
    .slider_bg_student {
      width: 100%;
      position: relative;
      height: calc(100vh - 70px);
      @include screen_mobile {
        flex-direction: column;
        height: calc(100vh - 70px);
        background-color: var(--white-color);
        border-bottom: 3px dashed var(--text-color);
      }
      .slider_2 {
        display: flex;
        align-items: center;
        max-width: 50%;
        margin: 0 auto;
        height: 100%;

        @include screen_mobile {
          align-items: flex-start;
          justify-content: center;
          max-width: 100% !important;
          object-fit: contain;
          max-height: 67%;
          width: 100%;
        }
        .slider_2_img {
          object-fit: contain;
          max-height: 90%;
          width: auto;

          @include screen_mobile {
            width: 100% !important;
          }
        }
      }
    }

    .title_container_student {
      max-width: 50%;
      margin: 0 auto;
      @include screen_mobile {
        max-width: 100% !important;
        text-align: center;
        margin-top: 2.5rem;
        width: 100%;
        padding: 0 3%;
        min-height: 33%;
      }

      .title_name_student {
        font-size: 1.4rem;
      }

      .title_detail_1_student {
        font-size: 2.2rem;
        background-color: #23d4a8;
        // padding: 0.5rem 1.5rem;
        width: 100%;
        padding: 0.5rem 0;
        text-align: center;
        color: var(--white-color);

        @include screen_mobile {
          padding: 0.2rem 0;
          background-color: var(--white-color);
          color: var(--text-color);
        }
      }

      .title_detail_2_student {
        @include screen_mobile {
          color: var(--primary-green) !important;
        }
      }
    }
  }

  .content {
    position: relative;
    padding: 7rem 0 5rem;
    .content_bg_benefit {
      width: 100%;

      .img_banner_student {
        width: 45%;
        object-fit: contain;
        @include screen_mobile {
          width: 94%;
        }
      }

      .content_title_student {
        margin: 3rem 0 3.5rem;
        text-align: center;

        @include screen_mobile {
          width: 80%;
        }
      }

      .list_content_student {
        width: 50%;
        @include screen_mobile {
          width: 96%;
        }
        .item_content_student {
          border: 1px solid var(--border-color);
          border-radius: 1rem;
          padding: 3rem 0;
          position: relative;
        }
      }
    }
  }
}
