@import "/src/_styles/mixin";

.coursesPage {
  position: relative;

  .content {
    .content_1 {
      // min-height: 120vh;
      z-index: 0;

      .img_courses_tree_container {
        width: 100%;
        height: 100%;
        z-index: -1;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        overflow: hidden;
        padding-right: 13%;
        img {
          position: relative;
          // width: 64rem;
          height: 93%;
          // min-width: 60rem;
          // min-height: 50rem;
          width: auto;
          overflow: hidden;
        }
      }

      .img_border_courses {
        height: 4.8rem;
        width: 92%;

        @include screen_tablet {
          width: 100%;
          height: 5rem;
          min-height: 5rem;
        }

        @include screen_mobile {
          width: 100%;
          // height: 4rem;
          // max-height: 4rem;
          height: 3rem;
          min-height: 3rem;
          // align-items: flex-start;
        }

        .course_title_name {
          font-size: clamp(1.4rem, 2vw, 1.625rem);

          @include screen_tablet {
            font-size: clamp(1.2rem, 2vw, 1.4rem);
          }

          @include screen_mobile {
            font-size: clamp(1.3rem, 5vw, 1.4rem);
          }
        }
      }

      .list_boxes {
        padding: 0 2%;

        .detail_item_course {
          background-color: var(--white-color);
          padding-top: 1.1rem;
          padding-bottom: 1.1rem;
          margin-bottom: 2rem;
        }

        .detail_item_2 {
          width: 85%;
          min-width: 20rem;
        }
        .detail_item_3,
        .detail_item_4 {
          width: 96%;
        }
        .detail_item_1 {
          width: 73%;
          min-width: 17rem;
        }
      }

      .list_boxes_1 {
        align-items: flex-end !important;
        .detail_item_2 {
          margin-right: 3.3vw;
        }

        .detail_item_3,
        .detail_item_1 {
          margin-right: 1.3vw;
        }
      }

      .list_boxes_2 {
        .detail_item_2 {
          margin-left: 2.5vw;
        }
        .detail_item_3 {
          margin-left: 0.8vw;
        }
      }

      .box_title {
        right: -10.8rem;
        top: 40%;
      }

      .box_title_1 {
        background-color: #e3bf0b;
      }
      .box_title_2 {
        background-color: #4db7d2;
      }
      .box_title_3 {
        background-color: #2dc296;
      }
      .box_title_4 {
        right: -8vw;
        background-color: #c84242;
      }

      // Image Bonus
      .img_bonus_1 {
        top: -6.5rem;
        left: 2rem;
        z-index: -1;
        img {
          width: 3vw;
          min-width: 3rem;
        }
      }

      .img_bonus_2 {
        top: -2.8rem;
        right: 0;
        // z-index: -1;
        img {
          width: 3.5vw;
          min-width: 3.5rem;
        }
      }

      .img_order_1 {
        bottom: 0;
        left: -1rem;
        // z-index: -1;
        img {
          width: 2vw;
          min-width: 2rem;
        }
      }

      .img_order_2 {
        top: -0.5rem;
        right: -0.7rem;
        // z-index: -1;
        img {
          width: 3vw;
          min-width: 3rem;
        }
      }

      .img_book {
        bottom: 4rem;
        right: -3.3rem;
        // z-index: -1;
        img {
          width: 4vw;
          min-width: 4rem;
        }
      }
    }
    .content_2 {
      min-height: 50vh;
      padding: 2rem 0;
      position: relative;
      background-color: var(--white-color);

      .img_table_convert_container {
        img {
          width: 60%;
          min-width: 40rem;
          z-index: 1;

          @include screen_mobile {
            width: 88%;
            min-width: 88%;
          }
        }
      }
      .icon_left_container {
        position: absolute;
        position: absolute;
        top: 20%;
        left: 0;
        img {
          width: 10vw;
          min-width: 8rem;
        }
      }

      .icon_right_container {
        position: absolute;
        top: 25%;
        right: 0;
        img {
          width: 10vw;
          min-width: 8rem;
        }
      }

      .img_bg_table_side {
        .img_bg_table_1 {
          top: 20%;
          right: 0;
          z-index: 0;
          img {
            width: 2vw;
            min-width: 2.3rem;
          }
        }
        .img_bg_table_2 {
          top: 32%;
          left: 0;
          z-index: 0;
          img {
            width: 3vw;
            min-width: 1.3rem;
          }
        }
        .img_bg_table_3 {
          bottom: 20%;
          left: 0;
          z-index: 0;
          img {
            width: 3vw;
            min-width: 4rem;
          }
        }
        .img_bg_table_4 {
          bottom: 42%;
          right: 0;
          z-index: 0;
          img {
            width: 3vw;
            min-width: 1.3rem;
            transform: scaleX(-1);
          }
        }
      }
    }

    // MOBILE
    .content_1_mobile {
      z-index: 0;

      .img_border_courses {
        height: 3rem;
        // width: 92%;s
        max-width: 100%;

        // @include screen_tablet {
        //   width: 100%;
        //   height: 5rem;
        //   min-height: 5rem;
        // }

        // @include screen_mobile {
        //   width: 100%;
        //   // height: 4rem;
        //   // max-height: 4rem;
        //   height: 3rem;
        //   min-height: 3rem;
        //   // align-items: flex-start;
        // }
      }

      .list_boxes_mobile {
        width: 84%;
        margin-bottom: 1.3rem;
        .detail_item_course_mobile:has(> .img_item_1) {
          padding-top: 0.3rem !important;
          padding-bottom: 0.3rem !important;
        }

        .detail_item_course_mobile {
          background-color: var(--white-color);
          padding-top: 2rem;
          padding-bottom: 1.5rem;
          margin-top: 2.2rem;

          .img_item_1 {
            padding-right: 0.8rem;
            position: relative;
            bottom: 1.3rem;
            img {
              width: 10%;
              min-width: 2.5rem;
            }
          }
          .img_item {
            .img_item_2 {
              top: -1.5rem;
              right: -1rem;
              // z-index: -1;
              img {
                width: 3.5vw;
                min-width: 3.5rem;
              }
            }
            .img_item_3 {
              top: -1.8rem;
              left: 0;
              // z-index: -1;
              img {
                width: 3.5vw;
                min-width: 3.5rem;
              }
            }

            .img_item_4 {
              top: -2rem;
              right: 2rem;
              // z-index: -1;
              img {
                width: 2.9vw;
                min-width: 2.9rem;
                transform: rotate(22deg);
              }
            }
          }

          // Box Title
          .box_title_even {
            top: -1rem;
            left: 2rem;
          }
          .box_title_odd {
            top: -1rem;
            right: 2rem;
          }

          .box_title_1 {
            background-color: #e3bf0b;
          }
          .box_title_2 {
            background-color: #4db7d2;
          }
          .box_title_3 {
            background-color: #2dc296;
          }
          .box_title_4 {
            right: -8vw;
            background-color: #c84242;
          }
        }
      }

      .img_bg_side {
        .img_bg_1 {
          top: 10%;
          right: 0;
          z-index: 0;
          img {
            width: 2vw;
            min-width: 1.5rem;
          }
        }
        .img_bg_2 {
          top: 20rem;
          left: 0;
          z-index: 0;
          img {
            width: 2vw;
            min-width: 1.5rem;
          }
        }
        .img_bg_3 {
          top: 40rem;
          left: 0;
          z-index: 0;
          img {
            width: 3vw;
            min-width: 3.8rem;
          }
        }
        .img_bg_4 {
          bottom: 33rem;
          right: 0;
          z-index: 0;
          img {
            width: 3vw;
            min-width: 3rem;
            transform: scaleX(-1);
          }
        }
        .img_bg_5 {
          bottom: 24rem;
          left: 0;
          z-index: 0;
          img {
            width: 3vw;
            min-width: 1.6rem;
          }
        }
      }
    }
  }
}
