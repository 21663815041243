@import "/src/_styles/mixin";

.video_banner_container {
  .video_banner {
    width: 13.9vw;
    // margin-top: 0.7vw;
    min-width: 19.5rem;
    @include screen_mobile {
      width: 60vw;
      min-width: 17rem;
    }
  }
  .icon_left_sliding_container {
    position: absolute;
    left: 0;
    top: 20%;
    // z-index: -1;
    img {
      width: 7vw;
      min-width: 11rem;
    }
  }
  .icon_center_sliding_container {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    top: 15%;
    // z-index: -1;
    padding-left: 4%;
    img {
      width: 28vw;
      min-width: 32rem;
    }
  }
  .icon_right_sliding_container {
    position: absolute;
    right: 5%;
    bottom: 5rem;
    // z-index: -1;
    img {
      width: 7vw;
      min-width: 9rem;
    }
  }

  // Mobile
  .icon_center_sliding_container_mobile {
    @include screen_mobile {
      position: absolute;
      // left: 34.5vw;
      // left: 50%;
      // right: 50%;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      top: 10%;
      //   z-index: -1;
      // padding-left: 3%;
      img {
        width: 100vw;
        // min-width: 28rem;
        // height: 30rem;
      }
    }
  }
}
