$student-color: #c2e68b;
$teacher-color: #8acec6;
$parent-color: #9cc4e6;

$student-text-color: #95bc59;
$teacher-text-color: #00a99d;
$parent-text-color: #6593bb;

$active-class: #00b9b7;
$normal-class: #979797;

@media screen and (max-width: 768px) {
    .add-class-form-container {
        padding: 40px 16px;
    }
}

.register_account_type {
    .login-form-container_content {
        @media screen and (max-height: 800px) {
            margin-top: -140px;
        }
    }

    span,
    p {
        font-size: 18px;
    }

    .step_register_name {
        margin-bottom: 20px;
    }

    .add-class-content {
        // margin-bottom: 24px;
        padding-bottom: 24px;

        &_list {
            display: flex;

            &_item {
                overflow: hidden;
                flex: 1;
                margin-right: 34px;
                position: relative;
                height: 127px;
                width: 127px;
                border-radius: 25px;
                cursor: pointer;

                &:last-child {
                    margin-right: 0px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        .add-class-content-label-list {
            font-size: 16px;
            margin-left: 8px;
            margin-bottom: 16px;
        }

        .add-class-content-list {
            display: flex;
            flex-direction: column;
            max-height: 42vh;
            padding: 16px 32px 0;
            overflow-y: auto;

            @media screen and (max-width: 768px) {
                overflow-y: unset;
                padding: 0;
                max-height: unset;
            }
        }

        .add-class-content-item {
            position: relative;
            margin-top: 16px;
        }

        .add-class-content-item:first-child {
            margin-top: 0;
        }

        .input_text_base_container {
            width: 100%;
        }

        .input_text_base {
            margin-bottom: 0;
        }

        .add-class-content-icon-remove {
            width: 30px;
            height: 30px;
            cursor: pointer;
            position: absolute;
            right: -10px;
            top: -10px;
            border-radius: 50%;
            background-color: red;
        }

        .add-class-content-icon-success {
            width: 30px;
            height: 30px;
            position: absolute;
            right: -10px;
            top: -10px;
            border-radius: 50%;
            background-color: #36ae4a;
        }

        .sub-action-container {
            display: flex;
            margin-top: 16px;
            padding: 0 32px;
            height: 40px;

            span {
                font-size: medium;
            }
        }

        .btn-add-more {
            width: 100px;
            background-image: none;
            background:
                linear-gradient(#F3FFFF, #F3FFFF) padding-box,
                linear-gradient(to right, #00b9b7, #00e1a0) border-box;
            height: 40px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid transparent;
            border-radius: 10px;

            span {
                font-size: medium;
                background: -webkit-linear-gradient(#00b9b7, #00e1a0);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                width: 100%;
            }

            :hover {
                opacity: 0.6;
            }
        }

        .btn-check {
            width: 100px;
            border-radius: 10px;
            padding: 0;
            margin-left: 16px;
        }
    }

    .button_submit {
        margin-bottom: 22px;
        margin-top: 40px;
    }

    .login_text {
        cursor: pointer;

        &:hover {

            p,
            a {
                color: #35b5c3;
            }
        }
    }

    .blur_role {
        opacity: 0.5;
    }
}