@import "/src/_styles/mixin";

.advisement_form {
  width: 100%;
  padding-top: 0.8rem;
  padding-bottom: 0rem;
  @include screen_mobile {
    width: 100%;
    padding-bottom: 0rem;
  }

  .content_advisement_container {
    width: 90%;
    max-width: 100rem;
    min-width: 55rem;
    border: 1px dashed #4d4d4d;
    border-radius: 2.2rem;
    padding: 1rem;
    @include screen_mobile {
      min-width: 100%;
      border: none;
      padding: 0;
      border-radius: 0rem;
    }
    .title_header {
      width: 70%;
      max-width: 70%;
      @include screen_mobile {
        width: 98%;
        max-width: 98%;
        margin: 0 auto;
      }
    }
    .title_header_mobile {
      padding: 1rem 0 1rem;
      .title_header_1 {
        @include screen_mobile {
          width: 50%;
          max-width: 50%;
          margin: 0 auto;
        }
      }
      .title_header_2 {
        @include screen_mobile {
          width: 84%;
          max-width: 84%;
          margin: 0 auto;
        }
      }
    }
    .content_advisement {
      width: 100%;
      padding: 2.5rem 0 0 5%;
      border-radius: 1.5rem;

      @include screen_mobile {
        border-radius: 0;
        padding: 1rem 0 0;
      }

      .content_left_advisement {
        width: 48%;
        max-width: 48%;
        padding-bottom: 2.5rem;

        @include screen_mobile {
          width: 92%;
          max-width: 92%;
          padding-bottom: 0;
          // padding:;
        }
      }

      .header_advisement {
        width: 100%;
        padding-bottom: 1.2rem;

        @include screen_mobile {
          padding-bottom: 0.5rem;
        }

        .title_header_teacher {
          width: 48%;
          max-width: 48%;
          padding: 1rem 0 0.5rem;

          @include screen_mobile {
            margin: 0 auto;
            width: 90%;
            max-width: 90%;
            text-align: center;
            display: flex;
            // justify-content: center;
          }
        }

        // .title_header {
        //   position: relative;
        //   background: transparent;
        //   z-index: 1;
        //   font-size: clamp(1.7rem, 3.3vw, 2.78125rem);

        //   &::after {
        //     content: attr(title);
        //     position: absolute;
        //     -webkit-text-stroke: 8px white;
        //     left: 0;
        //     z-index: -2;
        //   }

        //   &::before {
        //     content: attr(title);
        //     position: absolute;
        //     -webkit-text-stroke: 8px white; /* Chris Coyier's favorite color! */
        //     left: 0;
        //     z-index: -1;
        //   }
        // }

        .detail_header {
          width: 100%;
          font-size: clamp(1rem, 2vw, 1.5rem);
          line-height: 1.8rem;
          @include screen_mobile {
            margin: 0 auto;
            text-align: center;
            width: 90%;
            font-size: clamp(0.9rem, 4vw, 1rem);
            line-height: 1.225rem;
          }
        }
      }

      .form_input_advisement {
        width: 100%;
        padding-top: 1.2rem;

        @include screen_mobile {
          width: 98%;
          padding-top: 1.5rem;
        }

        .input_radio_base {
          .error_text_absolute {
            @include screen_mobile {
              bottom: calc(100%) !important;
            }
          }
          .error_select {
            span {
              font-size: 17px !important;

              @include screen_mobile {
                font-size: 0.82rem !important;
              }
            }
          }
        }

        .input_text_base_container {
          .error_text_absolute {
            @include screen_mobile {
              bottom: calc(100%) !important;
            }
          }
          .error_input {
            span {
              font-size: 17px !important;

              @include screen_mobile {
                font-size: 0.82rem !important;
              }
            }
          }
        }

        .input_text_base {
          padding: 0 1.2rem !important;
        }

        .input_text_base,
        .input_select_main_wrapper {
          height: clamp(3.2rem, 4vw, 3.375rem) !important;
          border: none;
          background-color: var(--white-color) !important;
          margin-bottom: 2.3rem;

          @include screen_mobile {
            margin-bottom: 2rem;
          }

          .input_text {
            color: var(--text-color);
            font-size: clamp(1rem, 1.6vw, 1.25rem);
            -webkit-text-fill-color: var(--text-color);
            &::placeholder {
              color: var(--text-color);
              -webkit-text-fill-color: var(--text-color);
            }
          }
        }
      }

      .img_side_advisement {
        width: 50%;
        max-width: 50%;

        @include screen_mobile {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}
.btn_container {
  padding-top: 2.25rem;

  .btn_custom {
    @include screen_mobile {
      max-height: 100% !important;
      max-width: 100% !important;
      width: clamp(9rem, 50vw, 11rem) !important;
      height: clamp(2.65rem, 12vw, 2.87rem) !important;
    }
    .text_btn {
      font-size: clamp(1.2rem, 2vw, 1.5rem);

      @include screen_mobile {
        font-size: 1.5rem;
      }
    }
  }
}

.advisement_form_teacher {
  width: 100%;
  .content_advisement_container {
    width: 90%;
    max-width: 100rem;
    min-width: 55rem;
    padding: 1rem;
    border: 1px dashed #4d4d4d;
    border-radius: 3rem;

    @include screen_mobile {
      min-width: 100%;
      border: none;
      padding: 0;
      border-radius: 0rem;
    }
    .content_advisement {
      background-color: #ffea99;
      border-radius: 2.5rem;
      padding: 2.5rem 4%;

      @include screen_mobile {
        border-radius: 0;
      }
      .header_advisement {
        .title_header_teacher_1_mobile {
          @include screen_mobile {
            width: 82%;
            max-width: 82%;
          }
        }
        .title_header_teacher_2_mobile {
          @include screen_mobile {
            width: 94%;
            max-width: 94%;
          }
        }
        .title_header_teacher {
          width: 92%;
          max-width: 90rem;
        }

        .detail_header {
          font-size: clamp(1.3rem, 1.8vw, 1.5rem);
          padding: 1rem 0 2.5rem;

          @include screen_mobile {
            font-size: 1rem;
            padding: 0.75rem 0 2rem;
          }
        }
      }

      .form_input_advisement {
        .form_input_wrapper {
          @include screen_mobile {
            flex-direction: column !important;
          }
        }
        .input_radio_base {
          .error_text_absolute {
            @include screen_mobile {
              bottom: calc(100%) !important;
            }
          }
          .error_select {
            span {
              font-size: 16px !important;

              @include screen_mobile {
                font-size: 0.82rem !important;
              }
            }
          }
        }
        .input_container_left,
        .input_container_right {
          width: 48%;
          max-width: 48%;

          @include screen_mobile {
            width: 98%;
            max-width: 98%;
          }
        }

        .text_note_assistant {
          font-size: clamp(0.9rem, 1.1vw, 1rem);
          margin-top: -0.75rem;

          @include screen_mobile {
            font-size: 0.75rem;
            margin-top: -1rem;
          }
        }
        .input_container_right {
        }

        .input_text_base_container {
          .error_text_absolute {
            @include screen_mobile {
              bottom: calc(100%) !important;
            }
          }
          .error_input {
            span {
              font-size: 17px !important;

              @include screen_mobile {
                font-size: 0.82rem !important;
              }
            }
          }
        }

        .input_text_base {
          padding: 0 1.2rem !important;
        }

        .input_text_base,
        .input_select_main_wrapper {
          height: clamp(3.2rem, 4vw, 3.375rem) !important;
          border: none;
          background-color: var(--white-color) !important;
          margin-bottom: 2.3rem;

          @include screen_mobile {
            margin-bottom: 2rem;
          }

          .input_text {
            color: var(--text-color);
            font-size: clamp(1rem, 1.6vw, 1.25rem);
            -webkit-text-fill-color: var(--text-color);
            &::placeholder {
              color: var(--text-color);
              -webkit-text-fill-color: var(--text-color);
            }
          }
        }

        .number_account_assistant {
          margin-top: -0.75rem;
          padding: 0 3.5%;

          @include screen_mobile {
            margin-top: 1rem;
          }

          .title_account_assistant {
            font-size: clamp(1.1rem, 1.3vw, 1.25rem);

            @include screen_mobile {
              font-size: clamp(0.75rem, 2.8vw, 0.875rem);
            }
          }

          .box_number_account {
            margin-top: 0.5rem;
            background-color: var(--white-color);
            border-radius: 1.5rem;
            border: 1px solid var(--text-color);
            width: 8.8rem;
            min-width: 6rem;
            padding: 0.35rem 0;
            font-size: clamp(1.3rem, 1.7vw, 1.5rem);

            @include screen_mobile {
              width: 7.5rem;
              min-width: 5.5rem;

              font-size: clamp(1.05rem, 2vw, 1.125rem);
            }

            .number_account_text {
              font-size: clamp(1.3rem, 1.7vw, 1.5rem);
              margin: 0 0.75rem;
              min-width: 2rem;

              @include screen_mobile {
                margin: 0 0.35rem;
                font-size: clamp(1.05rem, 2vw, 1.125rem);
              }
            }

            svg {
              @include screen_mobile {
                width: 14px;
                height: 11px;
              }
            }
          }
        }

        // Text Policy
        .text_policy_assistant_account {
          font-size: clamp(1.1rem, 1.5vw, 1.25rem);
          margin-top: 2.5rem;

          .ico_checkbox_policy {
            width: 1.25rem;
            height: auto;
            margin-right: 0.35rem;
          }
          span,
          a {
            font-size: clamp(1.1rem, 1.5vw, 1.25rem);

            @include screen_mobile {
              font-size: clamp(0.75rem, 3vw, 0.875rem);
            }
          }
        }
      }
    }
  }
}
