@import "/src/_styles/mixin";

.pagination_container {
  padding: 5rem 0;
  @include screen_mobile {
    padding: 4rem 0;
  }
}

.item_number_page {
  color: var(--white-color) !important;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  font-size: 1.5em;
  margin: 0 1rem;
  &:hover {
    background-color: #00e695 !important;
  }

  @include screen_mobile {
    // height: 2.5rem;
    // width: 2.5rem;
    font-size: 1.125rem;
    width: clamp(2.1rem, 10vw, 2.5rem);
    height: clamp(2.1rem, 10vw, 2.5rem);
    margin: 0 0.6rem;
  }
}
